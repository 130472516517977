code[class*="language-"], pre[class*="language-"] {
  color: #000;
  text-shadow: 0 1px #fff;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  -webkit-hyphens: none;
  hyphens: none;
  background: none;
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  font-size: 1em;
  line-height: 1.5;
}

code[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

code[class*="language-"]::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*="language-"]::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

code[class*="language-"] ::selection, code[class*="language-"]::selection, pre[class*="language-"] ::selection, pre[class*="language-"]::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*="language-"], pre[class*="language-"] {
    text-shadow: none;
  }
}

pre[class*="language-"] {
  margin: .5em 0;
  padding: 1em;
  overflow: auto;
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: #f5f2f0;
}

:not(pre) > code[class*="language-"] {
  white-space: normal;
  border-radius: .3em;
  padding: .1em;
}

.token.cdata, .token.comment, .token.doctype, .token.prolog {
  color: #708090;
}

.token.punctuation {
  color: #999;
}

.token.namespace {
  opacity: .7;
}

.token.boolean, .token.constant, .token.deleted, .token.number, .token.property, .token.symbol, .token.tag {
  color: #905;
}

.token.attr-name, .token.builtin, .token.char, .token.inserted, .token.selector, .token.string {
  color: #690;
}

.language-css .token.string, .style .token.string, .token.entity, .token.operator, .token.url {
  color: #9a6e3a;
  background: #ffffff80;
}

.token.atrule, .token.attr-value, .token.keyword {
  color: #07a;
}

.token.class-name, .token.function {
  color: #dd4a68;
}

.token.important, .token.regex, .token.variable {
  color: #e90;
}

.token.bold, .token.important {
  font-weight: 700;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

div.code-toolbar {
  position: relative;
}

div.code-toolbar > .toolbar {
  z-index: 10;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: .3em;
  right: .2em;
}

div.code-toolbar:hover > .toolbar, div.code-toolbar:focus-within > .toolbar {
  opacity: 1;
}

div.code-toolbar > .toolbar > .toolbar-item {
  display: inline-block;
}

div.code-toolbar > .toolbar > .toolbar-item > a {
  cursor: pointer;
}

div.code-toolbar > .toolbar > .toolbar-item > button {
  color: inherit;
  font: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  background: none;
  border: 0;
  padding: 0;
  line-height: normal;
  overflow: visible;
}

div.code-toolbar > .toolbar > .toolbar-item > a, div.code-toolbar > .toolbar > .toolbar-item > button, div.code-toolbar > .toolbar > .toolbar-item > span {
  color: #bbb;
  background: #e0e0e033;
  border-radius: .5em;
  padding: 0 .5em;
  font-size: .8em;
  box-shadow: 0 2px #0003;
}

div.code-toolbar > .toolbar > .toolbar-item > a:focus, div.code-toolbar > .toolbar > .toolbar-item > a:hover, div.code-toolbar > .toolbar > .toolbar-item > button:focus, div.code-toolbar > .toolbar > .toolbar-item > button:hover, div.code-toolbar > .toolbar > .toolbar-item > span:focus, div.code-toolbar > .toolbar > .toolbar-item > span:hover {
  color: inherit;
  text-decoration: none;
}
/*# sourceMappingURL=index.83b6ce87.css.map */
